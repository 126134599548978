import React, {useEffect, useState, createRef} from "react"
import { useRef } from "react/cjs/react.development"
import { Ref, Visibility } from "semantic-ui-react"
import Block from "./Block"
import { withRouter } from "../withRouter"
const qs = require('qs')


function parseQueryParamsToJson(queryString) {
    const params = new URLSearchParams(queryString);
    const result = {};
  
    for (const [key, value] of params.entries()) {
      result[key] = value;
    }
  
    return result;
}

class Page extends React.Component {
    constructor(props) {
        super(props)
        this.contextRef = createRef()
        
        this.state={
            activeBlock: 0,
            scrollPos: 0,
            blockCount: props.blocks.length,
            blockVisiblityOffset: 300
        }
        
    }

    componentDidMount() {
        if (!this.props.noBlocks) {
            let search = window.location.search.replace("?","")
            if(search!=="") {
                let queryObj=qs.parse(search)
                if(queryObj.block!==undefined) {
                    let index = parseInt(queryObj.block)
                    if(index>=0&&index<this.state.blockCount) {
                        this.switchBlock(index)
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        let params = parseQueryParamsToJson(this.props.location.search.replace("?", ""))
        let prevParams = parseQueryParamsToJson(prevProps.location.search.replace("?", ""))
        if (params.block !== prevParams.block || parseInt(params.refresh) > parseInt(prevParams.refresh ?? 0)) {
            this.switchBlock(params.block)
        }
    }

    handleScroll = (e) => {
        let element = e.target
        this.setState({scrollPos: element.scrollTop})
    }

    handleUpdate = (e, data, i) => {
        let clientHeight = this.contextRef.current.clientHeight
        let {scrollPos, activeBlock, blockVisiblityOffset, blockCount} =this.state 
        let blockTop = (activeBlock*clientHeight)-blockVisiblityOffset
        let blockBottom = ((activeBlock*clientHeight)+clientHeight)-blockVisiblityOffset
        if(scrollPos<blockTop&&activeBlock!==0){
            this.setState({activeBlock: activeBlock-1})
        }
        else if(scrollPos > blockBottom&&activeBlock!==(blockCount-1)){
            this.setState({activeBlock: activeBlock+1})
        }
    }

    switchBlock = (index) => {
        if (!this.props.noBlocks) {
            let clientHeight = this.contextRef.current.clientHeight
            //document.getElementsByClassName('page')[0].scrollTop=index*clientHeight
            document.getElementsByClassName('page')[0].scrollTo({
                top: index*clientHeight,
                behavior: 'smooth'
            })
            this.setState({activeBlock: index})
        }
    }

    render() {
        return (
            <>
            <div id="canvas" >
                    {this.props.background(this.state.scrollPos)}
            </div>
            <Ref innerRef={this.contextRef}>
                    <div className="page" onScroll={this.handleScroll}>
                        {
                            this.props.blocks.map((block, index)=>{
                                let title = undefined
                                let center = undefined
                                if(block.additionalOptions!==undefined) {
                                    if(block.additionalOptions.includes('title')){
                                        title = true
                                    }
                                     
                                   if(block.additionalOptions.includes('center')) {
                                        center = true
                                    }
                                }
                            
                                let active = this.state.activeBlock === index
                                return(
                                    <Block key={block.index} visible={active} index={block.index} title={title} center={center} handleUpdate={this.handleUpdate}>
                                    {
                                        block.content({switchBlock:this.switchBlock})
                                    }
                                    </Block>
                                )
                            })
                        }
                    </div>
                            
            </Ref> 
            </>
        )
    }
}

export default withRouter(Page)
import React, { useState,useEffect } from "react"
import { withRouter } from "./withRouter"
import { Button, Header, Placeholder, Image as Img, List } from "semantic-ui-react"
import Page from "./components/Page"
import AnimationCanvas from "./components/AnimationCanvas"

const fetchData = async (songId) => {
    try {
        const response = await fetch(`/info/${songId}.json`); // Assuming the file is named data.json

        if (response.status === 404) {
            return null
        }

        const jsonData = await response.json();
        return jsonData
        
    } catch (err) {
        console.log('error')
        return null
    }
}

const Songs = (props) => {
    let returned_url = props.location.hash.substr(1);
    let hash = returned_url.split("&");
    let accessToken

    if (returned_url!==""&&returned_url!==undefined) {
        accessToken = hash[0].split("=")[1] || 0
    }

    const extractId = (pathname) => {
        try {
            const regexp = new RegExp(/(?<prefix>\/songs\/)(?<song>[a-zA-Z0-9_-]*)$/)
            let match = regexp.exec(pathname)
            return match.groups.song
        }
        catch (err) {
            // Error
            return ''
        }
    }

    const loadImage = (image) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image()
          loadImg.src = image
          // wait 2 seconds to simulate loading time
          loadImg.onload = () =>
            setTimeout(() => {
              resolve(image.url)
            }, 2000)
  
          loadImg.onerror = err => reject(err)
        })
    }

    // Id
    const id = extractId(props.location.pathname)

    // State
    const [state, _setState] = useState({ song: {}, img: false, email: '', joined: false, accessToken: accessToken, loading: true})

    // Set State
    const setState = (curr) => {
        _setState(prev => ({...prev, ...curr}))
    }

    useEffect(async()=>{
        // Fetch json data
        let json = await fetchData(id)

        if (json === null) {
            console.log('error json')
            
            setState({song: null, loading: false, error: true})
            return
        }
    
        try {
            await loadImage(json.coverArt)
            setState({song: json, img: true, loading: false, error: undefined})
        } catch (err) {
            console.log("Failed to load images", err)
            setState({song: json, img: true, loading: false, error: true})
        }
    }, [])

    
    let background = (offsetY)=>{
        return(
            <>
                <AnimationCanvas offsetY={offsetY}/>
            </>
        )
    }

    let songBlockContent = (
        <> 
            {
                state.loading ? 
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div className="loader-new"></div>
                </div>
                :
                    state.song === null ?
                    <div className='newSongContent'>
                        <Header>SONG NOT FOUND</Header>
                    </div>
                    :
                    <div className='newSongContent'>
                        <Placeholder style={{ height: "auto", maxHeight: "300px", maxWidth: "300px"}}>
                            <Img src={state?.song?.coverArt} style={{ height: "auto", width: "100%", maxHeight: "300px", maxWidth: "300px"}}/>
                        </Placeholder>
                        <Header>{(state?.song?.name?? '').toUpperCase()}</Header>
                        <p>PROD. BY {(state?.song?.producedBy ?? '').toUpperCase()}</p>
                        <List className="musicPlatforms">
                            {(state?.song?.platforms ?? []).map((platform)=>{
                                return(
                                    <List.Item key={platform?.name}>
                                        <div>{platform?.name.toUpperCase()}</div>
                                        <Button secondary as='a' href={platform?.url}>PLAY</Button>
                                    </List.Item>
                                )
                            })}
                        </List>
                    </div>
            }
        </>
    )
    
    const blocks = [
        { index: 0, content: (props) => songBlockContent, additionalOptions: ['center']},
    ]
    
    return (
            <Page background={background} blocks={blocks} noBlocks={true} />
    )
}

export default withRouter(Songs)
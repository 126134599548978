import React, { useState,useEffect } from "react"
import { withRouter } from "./withRouter"
import { Button, Header, Placeholder, Image as Img, Form,List } from "semantic-ui-react"
import Page from "./components/Page"
import AnimationCanvas from "./components/AnimationCanvas"
import ApiManager from "./api/ApiManager"
import { useNavigate } from "react-router"

const fetchData = async (songId) => {
    try {
        const response = await fetch(`/info/${songId}.json`); // Assuming the file is named data.json

        if (response.status === 404) {
            return null
        }

        const jsonData = await response.json();
        return jsonData
        
    } catch (err) {
        console.log('error')
        return null
    }
}

const Songs = (props) => {
    let returned_url = props.location.hash.substr(1);
    let hash = returned_url.split("&");
    let accessToken
    const navigate = useNavigate()
    if (returned_url!==""&&returned_url!==undefined) {
        accessToken = hash[0].split("=")[1] || 0
    }

    const loadImage = (image) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image()
          loadImg.src = image
          // wait 2 seconds to simulate loading time
          loadImg.onload = () =>
            setTimeout(() => {
              resolve(image.url)
            }, 2000)
  
          loadImg.onerror = err => reject(err)
        })
    }

    // Id
    const id = 'hotel-cordial'
    const songName = 'HOTEL CORDIAL'

    // State
    const [state, _setState] = useState({ song: {}, img: false, email: '', joined: false, accessToken: accessToken, loading: true, email: '', joined: false})

    // Set State
    const setState = (curr) => {
        _setState(prev => ({...prev, ...curr}))
    }
    
    useEffect(async() => {
        // Fetch json data
        let json = await fetchData(id)

        if (json === null) {
            console.log('error json')
            
            setState({song: null, loading: false, error: true})
            return
        }
    
        try {
            await loadImage(json.coverArt)
            setState({song: json, img: true, loading: false, error: undefined})
        } catch (err) {
            console.log("Failed to load images", err)
            setState({song: json, img: true, loading: false, error: true})
        }
    }, [])

    
    let background = (offsetY)=>{
        return(
            <>
                <AnimationCanvas offsetY={offsetY}/>
            </>
        )
    }

    const joinTheClub = async() => {
            
            if(state.email==='') {
                setState({joined: "failed"})
                return 
            }
            fbq('trackCustom', 'join_the_club', {email: state.email});
            let res
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email))
            {
                res = await ApiManager.router.Subscribe.Join({email: state.email})
                if(res.status===200) {
                    setState({joined: true})
                }
                else {
                    setState({joined: "failed"})
                    return
                }
            }
            else {
                setState({joined: "failed"})
                return
            }
            
    }

    const handleChange = (e, {name, value}) => {
        setState({[name]:value})
    }

    let titleBlockContent = (
        <>  
            <Header as={"h1"}>KAYEANDRE</Header>
            <Button onClick={()=>navigate({search: `?block=1&refresh=${(new Date()).getTime()}`})}>STREAM "{songName}"</Button>
            <br></br>
            <div style={{position: "absolute", bottom:"15%", display:"flex", alignItems: "center", flexDirection:"column", color: "white"}}>
                <p>Find out more from the club?</p>
                <Button onClick={()=>navigate({search: `?block=2&refresh=${(new Date()).getTime()}`})}>CLUB PYM</Button>
            </div>
        </>
    )
    let songBlockContent = (
        <> 
            {
                state.loading ? 
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div className="loader-new"></div>
                </div>
                :
                    state.song === null ?
                    <div className='newSongContent'>
                        <Header>SONG NOT FOUND</Header>
                    </div>
                    :
                    <div className='newSongContent'>
                        <Placeholder style={{ height: "auto", maxHeight: "300px", maxWidth: "300px"}}>
                            <Img src={state?.song?.coverArt} style={{ height: "auto", width: "100%", maxHeight: "300px", maxWidth: "300px"}}/>
                        </Placeholder>
                        <Header>{(state?.song?.name?? '').toUpperCase()}</Header>
                        <p>PROD. BY {(state?.song?.producedBy ?? '').toUpperCase()}</p>
                        <List className="musicPlatforms">
                            {(state?.song?.platforms ?? []).map((platform)=>{
                                return(
                                    <List.Item key={platform?.name}>
                                        <div>{platform?.name.toUpperCase()}</div>
                                        <Button secondary as='a' href={platform?.url}>PLAY</Button>
                                    </List.Item>
                                )
                            })}
                        </List>
                    </div>
            }
        </>
    )
    
    let signUpBlockContent = (
        <>
            <div style={{width: "50%", height: "auto", background:"white", padding: "24px", minWidth: "288px"}}>
                <div className="flexcenter">
                    <Header>CLUB PYM</Header>
                    <p>All things PYM, find out what the hype is all about</p>
                <p style={{display: (state.joined==='failed')?'block':'none', color: "red"}}>Invalid email please try again later.</p>
                <Form.Input placeholder="Enter a valid email" name='email' value={state.email} onChange={handleChange} style={{display: (state.joined!==true)?'block':'none'}}></Form.Input>
                <br></br>
                <Button secondary style={{margin: "0px"}} onClick={async ()=>joinTheClub()} style={{display: (state.joined!==true)?'block':'none'}}>JOIN THE CLUB</Button>
                <p style={{display: (state.joined===true)?'block':'none', fontWeight: 600}}>Thanks For Joining CLUB PYM</p>
                </div>
                
            </div>
        </>
    )

    const blocks = [
        { index: 0, content:(props)=> titleBlockContent, additionalOptions: ['title']},
        { index: 1, content:(props)=> songBlockContent, additionalOptions: ['center']},
        { index: 2, content:(props)=> signUpBlockContent, additionalOptions: ['center']}
    ]
    
    return (
            <Page background={background} blocks={blocks} />
    )
}

export default withRouter(Songs)